
import EditableLabel from "@/components/common/EditableLabel.vue";
import { getDate, sizeInMB } from "@/utils";
import Vue from "vue";

export default Vue.extend({
  name: "ProjectDocumentCard",
  components: {
    EditableLabel,
  },
  props: {
    item: Object,
    isLocked: Boolean,
    selectedIds: Array
  },
  data() {
    return {
      isSelected: false
    };
  },
  computed: {
    date: function (): string {
      return getDate(this.item.updated_datetime || this.item.created_datetime)
    },
    extension: function():string {
      const tokens = this.item.name.split('.')
      return "." + (tokens[tokens.length-1]).toUpperCase()
    },
    size: function():string {
      return sizeInMB(this.item.size)
    }
  },
  methods: {
    updateTitle(val: string) {
      this.$emit("update", this.item.id, {
        title: val || "",
        description: this.item.description || "",
        projects_areas_of_interest_name: null,
      });
    },
    updateDescription(val: string) {
      this.$emit("update", this.item.id, {
        description: val || "",
        title: this.item.title || ""
      });
    },
    
    
    
  },
  watch: {
     
      selectedIds: {
        handler() {
          // console.log(this.selectedIds)
          if (this.selectedIds.indexOf(this.item.id) >= 0) {
            this.isSelected = true
          } else {
            this.isSelected = false
          }

        },
        deep: true
      }
    }
});
