
import Vue from "vue";

export default Vue.extend({
  name: "ImageDialog",
  props: {
    dialog: Boolean,
    title: String,
    image: String,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
  watch: {},
});
