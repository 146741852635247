
import { INote, notes } from "@/api";
import { Actions } from "@/store/models";
import Vue from "vue";
const DEFAULT_NOTE = "";
export default Vue.extend({
  name: "ProjectAreaOfInterestNotes",
  props: {
    isLocked: Boolean,
    areaOfInterest: Object,
  },
  data() {
    return {
      note: {
        note: DEFAULT_NOTE,
      } as INote,
    };
  },
  computed: {
    showable: function (): boolean {
      if (this.isLocked && !this.note.note) {
        return false;
      }
      return true;
    },
  },
  mounted() {
    this.getAll();
  },
  methods: {
    async getAll() {
      if (!this.areaOfInterest.id) {
        return;
      }
      const note = (await notes.areaOfInterest.get(this.areaOfInterest.id))
        .data[0];
      if (note) {
        this.note = note;
      } else {
        this.note = {
          note: DEFAULT_NOTE,
        } as INote;
      }
    },
    async save() {
      if (this.isLocked) {
        return;
      }

      try {
        if (this.note.id) {
          await notes.update(this.note.id, this.note);
        } else {
          await notes.areaOfInterest.add(this.areaOfInterest.id, this.note);
        }
        await this.getAll();
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
  },
  watch: {
    areaOfInterest: {
      deep: true,
      handler() {
        if (this.areaOfInterest.id) {
          this.getAll();
        }
      },
    },
  },
});
