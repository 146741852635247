
import Vue from "vue";
import ConfirmationDialog from "@/components/common/ConfirmationDialog.vue";
import { attachments, IAttachment } from "@/api";
import { Actions } from "@/store/models";
import ProjectNewDocumentCard from "./ProjectNewDocumentCard.vue";
import ProjectNewDocumentDialog from "./ProjectNewDocumentDialog.vue";
import ProjectDocumentCard from "./ProjectDocumentCard.vue";

export default Vue.extend({
  name: "ProjectDocuments",
  components: {
    ConfirmationDialog,
    ProjectNewDocumentCard,
    ProjectNewDocumentDialog,
    ProjectDocumentCard,
  },
  props: {
    project: Object,
    isLocked: Boolean,
  },
  data() {
    return {
      addDocumentDialog: false,
      deleteResultDialog: false,
      items: [] as IAttachment[],
      selectedIds: [] as number[],
    };
  },
  computed: {
    companyId: function (): number {
      return Number(this.$store.state.company.id);
    },
  },
  created() {
    this.getAll();
  },
  methods: {
    // dialogs
    toggleAddDocumentDialog(dialog: boolean) {
      this.addDocumentDialog = dialog;
    },
    toggleDeleteResultDialog(dialog: boolean) {
      this.deleteResultDialog = dialog;
    },
    // select
    select(id: number) {
      const index = this.selectedIds.indexOf(id);
      if (index < 0) {
        this.selectedIds.push(id);
      } else {
        this.selectedIds.splice(0, 1);
      }
    },
    selectAll() {
      if (this.selectedIds.length === this.items.length) {
        this.selectedIds = [];
      } else {
        this.selectedIds = [];
        this.items.forEach((item) => {
          this.selectedIds.push(item.id);
        });
      }
    },

    // CRUD
    async getAll() {
      try {
        this.items = (await attachments.documents.getAll(this.project.id)).data;
        // if on public report or any report view, do not need to update storage info 
        if (!this.isLocked) {
          await this.$store.dispatch(Actions.GetCompanyAccount);
        }
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    async add(item: any) {
      try {
        await attachments.documents.add(this.project.id, {
          ...item,
        });
        this.getAll();
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    async downloadDocument(id: number) {
      try {
        await attachments.downloadFromPresignedUrl(id);
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    async download() {
      for (let id of this.selectedIds) {
        await this.downloadDocument(id);
      }
    },
    async confirmRemove() {
      if (!this.selectedIds.length) {
        return;
      }
      this.deleteResultDialog = true;
    },
    async remove() {
      try {
        const fns = [] as any;
        this.selectedIds.forEach((id) => {
          fns.push(attachments.delete(id));
        });
        await Promise.all(fns);
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      } finally {
        this.getAll();
        this.selectedIds = [];
      }
    },
    async update(id: number, request: any) {
      try {
        await attachments.update(id, request);
        this.getAll();
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
  },
});
