
import { storage } from "@/api";
import { Actions } from "@/store/models";
import { byteToGB } from "@/utils";
import Vue from "vue";

export default Vue.extend({
  name: "ProjectNewDocumentDialog",
  props: {
    dialog: Boolean,
  },
  data() {
    return {
      item: {
        data: undefined as any,
      },
    };
  },
  computed: {
    projectId: function (): number {
      return Number(this.$route.params.id);
    },
  },
  methods: {
    async upload() {
      if (!this.item.data) {
        this.$store.dispatch(
          Actions.DisplayError,
          "Please fill in required details."
        );
        return;
      }

      const { data } = await storage.getNumbers();
      const fileSize = byteToGB(this.item.data.size);
      const storageLeft = data.storage_limit - data.storage_used;
      if (fileSize > storageLeft) {
        this.$store.dispatch(
          Actions.DisplayError,
          "File size exceeds storage space left."
        );
        return;
      }

      try {
        await this.$emit("onFileSelected", this.item);
        this.item = { data: undefined };
        this.$emit("toggleDialog", false);
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
  },
  watch: {},
});
