
import { attachments, INote, notes } from "@/api";
import { Actions } from "@/store/models";
import Vue from "vue";
import GMap from "@/components/common/GoogleMap.vue";
import ProjectDocuments from "./documents/ProjectDocuments.vue";
const DEFAULT_NOTE = "";
export default Vue.extend({
  name: "ProjectNotes",
  components: {
    GMap,
    ProjectDocuments,
  },
  props: {
    isLocked: Boolean,
    project: Object,
  },
  data() {
    return {
      note: {
        note: DEFAULT_NOTE,
      } as INote,
      showNotes: true,
      showDocuments: true,
      showFullMap: false,
    };
  },
  computed: {},
  created() {
    this.init();
  },
  methods: {
    async init() {
      await this.getAll();

      if (this.isLocked) {
        const docs = (await attachments.documents.getAll(this.project.id)).data;
        if (!this.note.note && !docs.length) {
          this.showFullMap = true;
        }
        if (!this.note.note) {
          this.showNotes = false;
        }
        if (!docs.length) {
          this.showDocuments = false;
        }
      }
    },
    async getAll() {
      const note = (await notes.project.get(this.project.id)).data[0];
      if (note) {
        this.note = note;
      } else {
        this.note = {
          note: DEFAULT_NOTE,
        } as INote;
      }
    },
    async save() {
      if (this.isLocked) {
        return;
      }
      try {
        if (this.note.id) {
          await notes.update(this.note.id, this.note);
        } else {
          if (!this.note) {
            return;
          }
          await notes.project.add(this.project.id, this.note);
        }
        await this.getAll();
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
  },
});
