
import { attachments } from "@/api";
import EditableLabel from "@/components/common/EditableLabel.vue";
import { Actions } from "@/store/models";
import { bufferToImage } from "@/utils";
import Vue from "vue";
import ImageDialog from "./ImageDialog.vue";

export default Vue.extend({
  name: "ProjectAttachmentCard",
  components: {
    EditableLabel,
    ImageDialog,
  },
  props: {
    item: Object,
    isLocked: Boolean,
  },
  data() {
    return {
      imageDialog: false,
      image: "",
      isSelected: false
    };
  },
  computed: {
    photo: function (): string {
      return this.item.cloud_file_icon?.data
        ? bufferToImage(this.item.cloud_file_icon.data)
        : "";
    },
  },
  methods: {
    updateTitle(val: string) {
      this.$emit("update", this.item.id, {
        title: val || "",
        description: this.item.description || "",
        projects_areas_of_interest_name:
          this.item.projects_areas_of_interest.name,
      });
    },
    updateDescription(val: string) {
      this.$emit("update", this.item.id, {
        description: val || "",
        title: this.item.title || "",
        projects_areas_of_interest_name:
          this.item.projects_areas_of_interest.name,
      });
    },
    async displayImage() {
      try {
        // const buffer = (await attachments.getImage(this.item.id)).data;
        // const typedArray: any = new Uint8Array(buffer);
        // this.image = bufferToImage(typedArray);
        this.image = (await attachments.getImageFromPresignedUrl(this.item.id)).data.presignedUrl
        this.imageDialog = true;
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
  },
});
